import { Box, Button, ButtonGroup, Center, chakra, Container, FormControl, FormHelperText, FormLabel, Heading, HStack, Input, InputGroup, InputRightElement, Spinner, Text, useToast, VStack } from "@chakra-ui/react";
import { Autocomplete } from "@react-google-maps/api";
import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useGeolocation } from "../contexts/GeolocationContext";
import Geocode from "react-geocode";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useBooking } from "../contexts/BookingContext";

import { isValidPhoneNumber } from "libphonenumber-js";
import { useLocalStorage } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../components/LanguageSelector";
import config from "../constant";
import useMonitor from "../hooks/monitor/useMonitor";
import axios from "axios";

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
Geocode.enableDebug();

export default function BookingPage() {
  const { t } = useTranslation();
  const { setBooking } = useBooking();
  const navigate = useNavigate();
  const toast = useToast();
  const { position } = useGeolocation();
  const nameRef = useRef(null);
  const idNumberRef = useRef(null);
  const emailRef = useRef(null);
  const vehicleTypeRef = useRef(null);
  const vehicleBrandRef = useRef(null);
  const vehicleModelRef = useRef(null);
  const vehicleColorRef = useRef(null);
  const vehicleYearRef = useRef(null);
  const vehicleNumberRef = useRef(null);
  const vehicleIssueRef = useRef(null);
  const insuranceInfoRef = useRef(null);
  const locationDetailsRef = useRef(null);

  const originRef = useRef(null);
  const destinationRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [pageSettings, setPageSettings] = useState({ page_title: "" });
  const [origin, setOrigin] = useState({ location: "", address: "" });
  const [destination, setDestination] = useState({ location: "", address: "" });
  const [phoneNumber, setPhoneNumber] = useState("");
  const { sendContactInformation, alertMessageText } = useMonitor();
  const [localPhoneNumber, setLocalPhoneNumber] = useLocalStorage({
    key: "x-phone-number",
    defaultValue: "",
  });

  const [localName, setLocalName] = useLocalStorage({
    key: "x-name",
    defaultValue: "",
  });

  useEffect(() => {
    getOrganization();
  }, []);

  const getOrganization = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${config.BASE_URL}/v1/admin/organization/${config.SUB_DOMAIN_PREFIX}`);
      setPageSettings(response.data);
      setIsLoading(false);
    } catch (e) {
      console.log("Error in getting linked organization ", e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    nameRef.current.value = localName;
  }, [localName]);

  useEffect(() => {
    setPhoneNumber(localPhoneNumber);
  }, [localPhoneNumber]);

  useEffect(() => {
    /** @type GeolocationPosition */
    if (position) {
      Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
        (response) => {
          const address = response.results[0]?.formatted_address;
          console.log(response.results[0]);
          if (originRef.current.value === "") {
            originRef.current.value = address;
            setOrigin({
              location: response.results[0]?.geometry.location,
              address: address,
            });
          }
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }, [position]);

  useEffect(() => {
    console.log("origin", origin, originRef);
    console.log("destination", destination);
  }, [origin, destination]);

  const handleOrigin = () => {
    Geocode.fromAddress(originRef.current.value).then(
      (response) => {
        const address = response.results[0]?.formatted_address;
        setOrigin({
          location: response.results[0]?.geometry.location,
          address: address,
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const handleDestination = () => {
    Geocode.fromAddress(destinationRef.current.value).then(
      (response) => {
        const address = response.results[0]?.formatted_address;
        setDestination({
          location: response.results[0]?.geometry.location,
          address: address,
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const showSpinner = () => {
    if (originRef.current != null && originRef.current.value === "") {
      return (
        <InputRightElement>
          <Spinner size="sm" />
        </InputRightElement>
      );
    }
    return null;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (nameRef.current.value === "") {
      showError(t("page2.nameIsRequired"));
      return;
    }
    if (origin.address === "" || origin.location === "") {
      showError(t("page2.originIsRequired"));
      return;
    }
    if (destination.address === "" || destination.location === "") {
      showError(t("page2.destinationIsRequired"));
      return;
    }
    if (phoneNumber === "") {
      showError(t("page2.phoneIsRequired"));
      return;
    }
    // const number = `${dialCode}${phoneNumber}`
    // Validate Number
    // console.log('number', number, dialCode, phoneNumber)
    console.log("isValidPhoneNumber", isValidPhoneNumber(`+${phoneNumber}`), phoneNumber);
    if (!isValidPhoneNumber(`+${phoneNumber}`)) {
      showError(t("page2.phoneInvalid"));
      return;
    }

    const booking = {
      name: nameRef.current.value,
      phoneNumber: `+${phoneNumber}`,
      origin,
      destination,
    };

    const emergencyPayload = {
      name: nameRef.current.value,
      phoneNumber: `+${phoneNumber}`,
      location: origin.address,
      locationCoordinates: {
        type: "Point",
        coordinates: [origin?.location?.lat, origin?.location?.lng],
      },
      destination: destination.address,
      destinationCoordinates: {
        type: "Point",
        coordinates: [destination?.location?.lat, destination?.location?.lng],
      },
      rutNumber: idNumberRef.current.value,
      email: emailRef.current.value,
      vehicleType: vehicleTypeRef.current.value,
      vehicleBrand: vehicleBrandRef.current.value,
      vehicleModel: vehicleModelRef.current.value,
      vehicleColor: vehicleColorRef.current.value,
      vehicleYear: vehicleYearRef.current.value,
      vehicleNumber: vehicleNumberRef.current.value,
      vehicleIssue: vehicleIssueRef.current.value,
      insuranceInfo: insuranceInfoRef.current.value,
    };
    /* 
    Setting values for local storage to be remembered for next booking
    */
    setLocalName(nameRef.current.value);
    setLocalPhoneNumber(phoneNumber);

    console.log("Final value", emergencyPayload);
    setBooking(booking);
    localStorage.setItem('tempContactData', JSON.stringify(emergencyPayload))
    // help.9911 flow
    await sendContactInformation(
      e,
      { ...emergencyPayload, linkedMonitor: pageSettings?.linked_monitor } // dynamic linked monitor
    );

    navigate('/confirm-booking-grua')
  };

  const showError = (message) => {
    toast({
      description: message,
      status: "error",
      duration: 2000,
      isClosable: true,
    });
  };

  return (
    <VStack minH="100vh" flexDirection="column" justifyContent="space-between">
      <Box alignSelf="flex-end" m={4}>
        <LanguageSelector />
      </Box>
      <VStack p={8} shadow={{ base: "none", md: "base" }} borderRadius="xl" maxW="container.md" alignItems="stretch" spacing={8}>
        {/* <HStack justifyContent='space-between'>
          <Heading>{t('page2.register')}</Heading>
          <Button
            as='a'
            href={t('helpUrl')}
            colorScheme='primary'
            target='_blank'
          >
            {t('page2.help')}
          </Button>
        </HStack> */}
        <Heading fontSize={20}>Ingresa tus datos y pronto atenderemos tu requerimiento</Heading>
        <chakra.form onSubmit={onSubmit}>
          <VStack alignItems="stretch" spacing={4}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "space-between" }}>
              <FormControl marginRight={4}>
                <FormLabel htmlFor="name">{t("page2_grua.nameAndSurname")}</FormLabel>
                <Input id="name" type="text" ref={nameRef} isRequired />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="name">{t("page2_grua.idNumber")}</FormLabel>
                <Input id="idNumber" type="text" ref={idNumberRef} isRequired />
              </FormControl>
            </div>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "space-between" }}>
              <FormControl marginRight={4}>
                <FormLabel htmlFor="name">{t("page2_grua.email")}</FormLabel>
                <Input id="email" type="text" ref={emailRef} isRequired />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="name">{t("page2_grua.vehicleType")}</FormLabel>
                <Input id="vehicleType" type="text" ref={vehicleTypeRef} />
              </FormControl>
            </div>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "space-between" }}>
              <FormControl marginRight={4}>
                <FormLabel htmlFor="name">{t("page2_grua.brand")}</FormLabel>
                <Input id="vehicleBrand" type="text" ref={vehicleBrandRef} />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="name">{t("page2_grua.model")}</FormLabel>
                <Input id="vehicleModel" type="text" ref={vehicleModelRef} />
              </FormControl>
            </div>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "space-between" }}>
              <FormControl marginRight={4}>
                <FormLabel htmlFor="name">{t("page2_grua.color")}</FormLabel>
                <Input id="vehicleColor" type="text" ref={vehicleColorRef} />
              </FormControl>
              <FormControl marginRight={4}>
                <FormLabel htmlFor="name">{t("page2_grua.year")}</FormLabel>
                <Input id="vehicleYear" type="text" ref={vehicleYearRef} />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="name">Patente</FormLabel>
                <Input id="vehicleNumber" type="text" ref={vehicleNumberRef} />
              </FormControl>
            </div>
            <FormControl>
              <FormLabel htmlFor="name">{t("page2_grua.issue")}</FormLabel>
              <Input id="vehicleIssue" type="text" ref={vehicleIssueRef} />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="phoneNumber">{t("page2.phoneNumber")}</FormLabel>
              {/* <Input id='phoneNumber' type='text' ref={phoneNumberRef} /> */}
              <PhoneInput
                country={"us"}
                value={phoneNumber}
                onChange={(value, country, e, formattedValue) => {
                  console.log(value, country);
                  setPhoneNumber(value);
                }}
                countryCodeEditable={false}
                enableSearch
                inputProps={{
                  name: "phone",
                  required: true,
                }}
                inputStyle={{
                  width: "100%",
                  borderColor: "#e4e4e7",
                  borderRadius: "6px",
                }}
                buttonStyle={{
                  backgroundColor: "white",
                  borderColor: "#e4e4e7",
                }}
              />
            </FormControl>
              <FormControl>
              <FormLabel htmlFor="name">{t("page2_grua.insurance")}</FormLabel>
              <Input id="insuranceInfo" type="text" ref={insuranceInfoRef} />
            </FormControl>
            <Autocomplete onPlaceChanged={handleOrigin}>
              <FormControl>
                <FormLabel htmlFor="origin">{t("page2.origin")}</FormLabel>
                <InputGroup>
                  <Input id="origin" type="text" ref={originRef} isRequired />
                  <InputRightElement display={origin.address === "" ? "inline-flex" : "none"}>
                    <Spinner size="sm" />
                  </InputRightElement>
                </InputGroup>
              </FormControl>
            </Autocomplete>
            <Text fontSize="sm">{t("page2_grua.gpsLocation")}</Text>
            <FormControl>
              <FormLabel htmlFor="name">{t("page2_grua.locationDetails")}</FormLabel>
              <Input id="locationDetails" type="text" ref={locationDetailsRef} />
            </FormControl>
            <Autocomplete onPlaceChanged={handleDestination}>
              <FormControl>
                <FormLabel htmlFor="destination">{t("page2.destination")}</FormLabel>
                <Input id="destination" type="text" ref={destinationRef} isRequired />
              </FormControl>
            </Autocomplete>
            <Button colorScheme="primary" type="submit" alignSelf="self-start">
              {t("page2.calculateRoute")}
            </Button>
          </VStack>
        </chakra.form>
      </VStack>
      <Text fontSize="sm" py={8}>
        {t("footer.copyright")} © {new Date().getFullYear()} Phone2Web Spa. {t("footer.message")}
      </Text>
    </VStack>
  );
}
