import {
  Box,
  Button,
  chakra,
  Container,
  Heading,
  HStack,
  Icon,
  Spinner,
  Stat,
  StatLabel,
  StatNumber,
  Tag,
  TagLabel,
  Text,
  VStack,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaPhone, FaPhoneAlt, FaStar } from 'react-icons/fa'
import Rating from 'react-rating'
import { Link, useNavigate, Navigate } from 'react-router-dom'
import useSWR from 'swr'
import { useBooking } from '../contexts/BookingContext'
import api from '../lib/api-client'
import { TRIP_STATUS, VEHICLE_TYPE } from '../utils/config'

let CURRENT_TRIP_ID

export default function CheckoutPage() {
  const navigate = useNavigate()

  const { t } = useTranslation()

  const {
    booking,
    driver,
    distanceText,
    durationText,
    fare,
    distanceInMeters,
    durationInSeconds,
  } = useBooking()

  const [tripId, setTripId] = useState(null)
  const [tripError, setTripError] = useState(null)

  const [driverArrivalTime, setDriverArrivalTime] = useState('')

  const [duration, setDuration] = useState(120);
  const [paused, setPaused] = useState(false);

  useEffect(() => {
    let timerId;
    if (!paused) {
      timerId = setInterval(() => {
        duration === 0 && setPaused(true)
        setDuration((prev) => prev > 0 && prev - 1);
      }, 1000);
      console.log(timerId, duration, formatToMSS(duration));
    }

    return function cleanup() {
      console.log(`Clearing ${timerId}`);
      clearInterval(timerId);
    };
  }, [paused])

  function formatToMSS(s) { return (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s }

  function deleteTrip() {
    api
      .put(`/trip`, { id: CURRENT_TRIP_ID })
      .then(response => {
        console.log(response.status, response.statusText, response.data)
      })
      .catch(error => console.log(error))
  }

  useEffect(() => {
    if (!booking || !driver || !distanceInMeters || !durationInSeconds) {
      return
    }
    // now we can request a trip
    const body = {
      driverId: driver._id,
      passengerName: booking.name,
      passengerPhoneNumber: booking.phoneNumber,
      origin: {
        address: booking.origin.address,
        location: {
          latitude: booking.origin.location.lat,
          longitude: booking.origin.location.lng,
        },
      },
      destination: {
        address: booking.destination.address,
        location: {
          latitude: booking.destination.location.lat,
          longitude: booking.destination.location.lng,
        },
      },
      distance: distanceInMeters,
      duration: durationInSeconds,
      distanceText: distanceText,
      durationText: durationText,
    }
    api
      .post('/request-ride', body)
      .then(response => {
        console.log(response)
        // setTrip(response)
        setTripId(response._id)
        setTripError(null)
      })
      .catch(error => {
        console.log(error.status, error.message, error.message.data.id)
        console.log(error.message)
        setTripError(t('page4.waitingForResponse'))
        setTripId(error.message.data.id)
        CURRENT_TRIP_ID = error.message.data.id
      })
  }, [booking, driver, fare, distanceInMeters, durationInSeconds])

  const { data: trip, error } = useSWR(tripId ? `/trip/${tripId}` : null, api, {
    refreshInterval: 10,
  })

  useEffect(() => {
    // Calculate driver arrival time
    async function calculateRouteBetweenDriverAndOrigin() {
      if (
        !booking ||
        booking.origin?.address === '' ||
        booking.destination?.address === '' ||
        !driver
      ) {
        return
      }
      // eslint-disable-next-line no-undef
      const directionsService = new google.maps.DirectionsService()
      /** @type google.maps.DirectionsResult */
      const result = await directionsService.route({
        origin: {
          lat: booking.origin.location.lat,
          lng: booking.origin.location.lng,
        },
        destination: {
          lat: driver.location.coordinates[1],
          lng: driver.location.coordinates[0],
        },
        // eslint-disable-next-line no-undef
        travelMode: google.maps.TravelMode.DRIVING,
      })
      console.log(result)
      console.log(result.routes[0].legs[0].duration.text)
      setDriverArrivalTime(result.routes[0].legs[0].duration.text)
    }
    if (trip && trip.status === TRIP_STATUS.accepted) {
      calculateRouteBetweenDriverAndOrigin()

    }
    if (trip && trip.status !== TRIP_STATUS.pending) {
      setTripError(null)
    }
  }, [trip, booking, driver])

  if (!booking || !driver || !fare) {
    return <Navigate to='/confirm-booking' replace={true} />
  }

  function rateTrip(rating) {
    api
      .patch(`/rate-trip/${tripId}`, { rating })
      .then(response => {
        console.log(response.status, response.statusText, response.data)
      })
      .catch(error => console.log(error))
  }

  if (tripError) {
    return (
      <Box my={8}>
        <Container
          shadow={{ base: 'none', md: 'base' }}
          p={{ base: 2, md: 4 }}
          borderRadius='lg'
          maxW='container.sm'
        >
          <VStack spacing={8}>
            <Tag colorScheme='red'>
              <TagLabel>{duration ? tripError : 'Driver not reachable/available, please try again or select another driver'}</TagLabel>
            </Tag>
            <Tag colorScheme={duration ? 'green' : 'yellow'}>
              <TagLabel>{formatToMSS(duration)}</TagLabel>
            </Tag>

            <Link to='/confirm-booking' replace={true}>
              <Button variant='link' size='sm' onClick={deleteTrip}>
                {/* Choose another driver */}
                {t('page4.chooseAnotherDriver')}
              </Button>
            </Link>
          </VStack>
        </Container>
      </Box>
    )
  }

  function getTranslations(status) {
    switch (status) {
      case TRIP_STATUS.pending:
        return t('page4.pending')
      case TRIP_STATUS.accepted:
        return t('page4.accepted')
      case TRIP_STATUS.declined:
        return t('page4.declined')
      case TRIP_STATUS.cancelled:
        return t('page4.cancelled')
      case TRIP_STATUS.started:
        return t('page4.started')
      case TRIP_STATUS.completed:
        return t('page4.completed')
      default:
        return ''
    }
  }

  return (
    <Box my={8}>
      <Container
        shadow={{ base: 'none', md: 'base' }}
        p={{ base: 2, md: 4 }}
        borderRadius='lg'
        maxW='container.sm'
      >
        <VStack alignItems='flex-start' spacing={4}>
          {/* <Heading alignSelf='center'>Trip details</Heading> */}
          <Heading alignSelf='center'>{t('page4.tripDetails')}</Heading>
          <Text fontWeight='bold'>
            {/* Origin: */}
            {t('page4.origin')}
            <chakra.span fontWeight='normal' ml={2}>
              {booking.origin.address}
            </chakra.span>
          </Text>
          <Text fontWeight='bold'>
            {/* Destination: */}
            {t('page4.destination')}
            <chakra.span fontWeight='normal' ml={2}>
              {booking.destination.address}
            </chakra.span>
          </Text>
          <Text fontWeight='bold'>
            {/* Distance: */}
            {t('page4.distance')}
            <chakra.span fontWeight='normal' ml={2}>
              {distanceText}
            </chakra.span>
          </Text>
          <Text fontWeight='bold'>
            {/* Duration: */}
            {t('page4.duration')}
            <chakra.span fontWeight='normal' ml={2}>
              {durationText}
            </chakra.span>
          </Text>
          <Text fontWeight='bold'>
            {/* Fare: */}
            {t('page4.tariff')}:
            {trip ? (
              <>
                <chakra.span fontWeight='normal' ml={2}>
                  {Math.ceil(trip.fare)}
                </chakra.span>
                <chakra.span ml={2} fontWeight='medium' fontSize='sm'>
                  {fare.currency}
                </chakra.span>
              </>
            ) : (
              <Spinner size='sm' ml={2} />
            )}
          </Text>
          <Text fontWeight='bold'>
            {/* Driver name: */}
            {t('page4.driver')}:
            <chakra.span fontWeight='normal' ml={2}>
              {driver.fullName}
            </chakra.span>
          </Text>
          <Text fontWeight='bold'>
            {/* Vehicle number: */}
            {t('page4.vehicleRegistrationNumber')}:
            <chakra.span fontWeight='normal' ml={2}>
              {driver.vehicle.registrationNumber}
            </chakra.span>
          </Text>
          {trip?.status !== TRIP_STATUS.pending &&
            trip?.status !== TRIP_STATUS.declined && (
              <Button
                as='a'
                leftIcon={<FaPhoneAlt />}
                colorScheme='green'
                href={`tel:${driver.phoneNumber}`}
              >
                {/* Call driver */}
                {/* Llamar al conductor */}
                {t('page4.callDriver')}
              </Button>
            )}

          {trip ? (
            <VStack spacing={4} alignSelf='center'>
              {/* <Text>Booking status: {trip.status}</Text> */}
              <Text>
                {t('page4.bookingStatus')}: {getTranslations(trip.status)}{' '}
              </Text>
              {trip.status === TRIP_STATUS.accepted && driverArrivalTime && (
                // <>Driver arriving in {driverArrivalTime}</>
                <>
                  {t('page4.driverArrivesIn')} {driverArrivalTime}
                </>
              )}
              {(trip.status === TRIP_STATUS.declined ||
                trip.status === TRIP_STATUS.cancelled) && (
                  <Link to='/confirm-booking' replace={true}>
                    {/* <Button variant='link'>Choose another driver</Button> */}
                    <Button variant='link'>{t('page4.chooseAnotherTaxi')}</Button>
                  </Link>
                )}
            </VStack>
          ) : (
            <VStack alignSelf='center'>
              <Spinner textAlign='center' />
              {/* <Text>Confirming booking, please wait...</Text> */}
              <Text>{t('page4.confirmingBooking')}</Text>
            </VStack>
          )}

          {trip?.status === TRIP_STATUS.completed && (
            <VStack alignSelf='center' spacing={4}>
              <HStack spacing={4}>
                <Text>{t('page4.rateRide')}</Text>
                <Rating
                  onChange={val => rateTrip(val)}
                  emptySymbol={
                    <Icon as={FaStar} boxSize='1.5rem' color='gray.200' />
                  }
                  fullSymbol={
                    <Icon as={FaStar} boxSize='1.5rem' color='yellow.500' />
                  }
                />
              </HStack>
              <Link to='/' replace={true}>
                <Button variant='link'>{t('page4.backToHome')}</Button>
              </Link>
            </VStack>
          )}
        </VStack>

        {/* <pre>
          {JSON.stringify(
            { booking, driver, distanceText, durationText, fare },
            null,
            2
          )}
        </pre> */}
      </Container>
    </Box>
  )
}
